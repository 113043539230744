import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { AiFillPlusCircle } from "react-icons/ai";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SoftBadge from "components/common/SoftBadge";
import { FaGlobeAmericas } from "react-icons/fa";
import { MdLocalPhone, MdOutlineLocationOn } from "react-icons/md";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import ListLoading from "module/common/ListLoading";
import { IoIosMail } from "react-icons/io";
import AddContact from "./Form/AddContact";
import useAxisproPermission from "hooks/useAxisproPermission";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import ProfileImage from "assets/img/Avathar/ProfileImage.jpg";

function Contacts({ type, itemId, overview }) {
  const navigate = useNavigate();
  const [contact, setContact] = useState([]);
  const [queryParams] = useSearchParams();
  const { overviewId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { dispatch } = useContext(DeleteContext);
  const axisProPermission = useAxisproPermission();
  const permissionObject = {
    //lead Permissions
    lead_store_contact:
      axisProPermission("store-lead-contact") && type === "LEAD",
    lead_update_contact:
      axisProPermission("update-lead-contact") && type === "LEAD",
    lead_delete_contact:
      axisProPermission("delete-lead-contact") && type === "LEAD",
    //opportunity Permissions
    opportunity_store_contact:
      axisProPermission("store-opportunity-contact") && type === "OPPORTUNITY",
    opportunity_update_contact:
      axisProPermission("update-opportunity-contact") && type === "OPPORTUNITY",
    opportunity_delete_contact:
      axisProPermission("delete-opportunity-contact") && type === "OPPORTUNITY",
  };

  const fetchData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/contacts",
      params: {
        subject_type:
          type === "LEAD" || overview === "lead" ? "LEAD" : "OPPORTUNITY",
        subject_id: itemId,
      },
    });

    setContact(data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "crm/contacts/" + id,
        title: `Delete contact`,
        message: `Are you sure you want to delete this contact?`,
        onSuccess: () => {
          fetchData();
        },
      },
    });
  };

  return (
    <>
      {!isLoading ? (
        <Card className="d-flex shadow-none">
          <Card.Header
            className={`rounded-0 pb-2 pt-2`}
            style={{
              backgroundColor: "#5f6f83",
              color: "#fff",
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="">CONTACT INFORMATION</div>
              {((!overviewId && itemId) || type === "LEAD") && (
                <AddContact getData={fetchData} type={type} id={itemId} />
              )}
              {(permissionObject.lead_store_contact ||
                permissionObject.opportunity_store_contact) && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `?${createSearchParams({
                        ...allQueryParams,
                        add_contact: true,
                      })}`
                    );
                  }}
                  title="Add Contact"
                >
                  <AiFillPlusCircle size={22} className={`text-light`} />
                </div>
              )}
            </div>
          </Card.Header>
          <Card.Body
            className={`rounded-0`}
            style={{
              height:
                type === "OPPORTUNITY" ? "64.6vh" : overview ? "58vh" : "72vh",
              overflow: "auto",
            }}
          >
            <Row className="w-100 gy-3 gx-3">
              {contact?.length > 0 ? (
                contact?.map((items, index) => {
                  return (
                    <Col
                      key={index}
                      xl={overview ? 0 : 3}
                      lg={overview ? 6 : 4}
                      md={6}
                    >
                      <div
                        className="border-dashed h-100 overflow-auto mb-3 d-flex flex-column 
                            justify-content-between"
                      >
                        <div className="p-3 pb-2 pt-2 position-relative">
                          <div className="d-flex justify-content-between">
                            <div
                              className={`w-100 d-flex ${
                                items.is_primary_contact
                                  ? "align-items-start "
                                  : "align-items-center"
                              }  mt-2 mb-2 me-4`}
                            >
                              <Image
                                src={ProfileImage}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                              <div className="d-flex flex-column  ms-2">
                                <span className="fw-bold">
                                  {items?.full_name}
                                </span>
                                {items.is_primary_contact ? (
                                  <span className="text-primary fw-bold">
                                    Primary
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {(permissionObject.lead_update_contact ||
                              permissionObject.lead_delete_contact ||
                              permissionObject.opportunity_update_contact ||
                              permissionObject.opportunity_delete_contact) && (
                              <div className="d-flex justify-content-start align-items-end position-absolute top-0 end-0 p-1">
                                {(permissionObject.lead_update_contact ||
                                  permissionObject.opportunity_update_contact) && (
                                  <EditButton
                                    route={`?${createSearchParams({
                                      ...allQueryParams,
                                      add_contact: true,
                                      edit_id: items.id,
                                    })}`}
                                  />
                                )}
                                {(permissionObject.lead_delete_contact ||
                                  permissionObject.opportunity_delete_contact) && (
                                  <DeleteButton
                                    onClick={() => handleDelete(items.id)}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                          {items?.phone_no && (
                            <ContactSingleDetails
                              label={"Number"}
                              variant={"warning"}
                              data={items?.phone_no}
                              icon={<MdLocalPhone size={20} />}
                            />
                          )}

                          {items?.email && (
                            <ContactSingleDetails
                              label={"Email"}
                              data={
                                items?.email +
                                ", " +
                                (items?.secondary_email
                                  ? items?.secondary_email
                                  : "")
                              }
                              variant={"danger"}
                              icon={<IoIosMail size={20} />}
                            />
                          )}
                          {items?.website && (
                            <ContactSingleDetails
                              label={"Website"}
                              data={items?.website}
                              variant={"primary"}
                              icon={<FaGlobeAmericas size={20} />}
                            />
                          )}
                          {(items?.country ||
                            items?.state ||
                            items?.city ||
                            items?.zip_code) && (
                            <ContactSingleDetails
                              label={"Location"}
                              data={
                                (items?.country ? items.country + ", " : "") +
                                (items?.state ? items.state + ", " : "") +
                                (items?.city ? items.city + ", " : "") +
                                (items?.zip_code ? items.zip_code : "")
                              }
                              variant={"warning"}
                              icon={<MdOutlineLocationOn size={20} />}
                            />
                          )}
                          {items.address && (
                            <ContactSingleDetails
                              label={"Address"}
                              data={items.address}
                              variant={"dark"}
                              icon={<MdOutlineLocationOn size={20} />}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  No contact Information! 🙂
                </div>
              )}
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <ListLoading />
      )}
    </>
  );
}

export default Contacts;
const ContactSingleDetails = ({ label, data, variant, icon }) => {
  return (
    <>
      <div className="ms-2">
        <ListGroup>
          <ListGroupItem className="border-0 p-0 pt-1 pb-1">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <span>
                <SoftBadge bg={variant} className="p-1 rounded-2">
                  {icon}
                </SoftBadge>
              </span>
              <div className="d-flex flex-column">
                <span style={{ color: "rgb(97,111,166)" }}>{label}</span>
                <span>{data}</span>
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>
      </div>
    </>
  );
};
