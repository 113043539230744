import { React } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";

const SelectCallTo = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  itemId,
  type,
  onFocus,
}) => {
  const option =
    type === "CUSTOMER"
      ? {
          url: "sales/customer-contact-autocomplete",
          setParams: (searchKey) => ({
            subject_type: type,
            customer_id: itemId,
            name: searchKey,
          }),
        }
      : {
          url: "crm/contacts",
          setParams: () => ({
            subject_type: type === "LEAD" ? "LEAD" : "OPPORTUNITY",
            subject_id: itemId,
          }),
        };

  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      ...option,
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
    });

  const setOptionData = (option) => ({
    label:
      type === "CUSTOMER"
        ? `${option.contact_mobile ? option.contact_mobile + "-" : ""}${
            option.contact_name
          }`
        : `${option.phone_no ? option.phone_no + "-" : ""}${option.first_name}`,
    value: option.id,
  });

  return (
    <AsyncSelect
      key={JSON.stringify(itemId)}
      cacheOptions
      loadOptions={fetchData}
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      isLoading={loading}
      isClearable
      name={name ? name : "purpose"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      classNamePrefix="app-react-select"
      className={`custom-select${
        error ? " form-control ps-0 py-0 is-invalid" : ""
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
          fontSize: "13px",
          borderRadius: "0px",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#696c96" : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

SelectCallTo.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  itemId: PropTypes.string,
};

export default SelectCallTo;
