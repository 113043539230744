import AppTimePicker from "components/app-time-picker/AppTimePicker";
import BackButton from "components/common/BackButton";
import SelectCallPurpose from "components/form/SelectCallPurpose";
import SelectCallResult from "components/form/SelectCallResult";
import SelectCallTo from "components/form/SelectCallTo";
import SelectCallType from "components/form/SelectCallType";
import SelectMeetingStatus from "components/form/SelectMeetingStatus";
import { SaveButton } from "module/common/Buttons/CommonButton";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
const AddCalls = ({
  handleFieldChange,
  formData,
  formError,
  onSave,
  handleSubmit,
  itemId,
  type,
}) => {
  return (
    <>
      <Form className="p-3">
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="require-data">Purpose</Form.Label>
              <SelectCallPurpose
                name="purpose"
                className="rounded-0"
                value={formData.purpose_ref}
                handleFieldChange={handleFieldChange}
                error={!!formError.purpose}
              />
              <Form.Control.Feedback type="invalid">
                {formError.purpose}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Time</Form.Label>
              <AppTimePicker
                name="start_time"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                hourPlaceholder="hh"
                minutePlaceholder="mm"
                onChange={handleFieldChange}
                value={formData.start_time}
                isInvalid={!!formError.start_time}
              />
              <div className="invalid-feedback d-block">
                {formError.start_time}
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="require-data">Call To</Form.Label>
              <SelectCallTo
                name="call_to"
                value={formData.call_to_ref}
                handleFieldChange={handleFieldChange}
                itemId={itemId}
                error={!!formError.call_to}
                type={type}
              />

              <Form.Control.Feedback type="invalid">
                {formError.call_to}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="require-data">Type</Form.Label>
              <SelectCallType
                name="type"
                value={formData.type_ref}
                handleFieldChange={handleFieldChange}
                error={!!formError.type}
                isClearable={false}
              />
              <Form.Control.Feedback type="invalid">
                {formError.type}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="require-data">Status</Form.Label>
              <SelectMeetingStatus
                name="status"
                value={formData.status_ref}
                handleFieldChange={handleFieldChange}
                error={!!formError.status}
                isClearable={false}
              />

              <Form.Control.Feedback type="invalid">
                {formError.status}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label
                className={
                  formData?.status === "SCHEDULED" ? "" : "require-data"
                }
              >
                Call Result
              </Form.Label>
              <SelectCallResult
                name="out_call_result"
                value={formData.out_call_result_ref}
                handleFieldChange={handleFieldChange}
                error={!!formError.out_call_result}
              />
              <Form.Control.Feedback type="invalid">
                {formError.out_call_result}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>End Time</Form.Label>
              <AppTimePicker
                name="end_time"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                hourPlaceholder="hh"
                minutePlaceholder="mm"
                onChange={handleFieldChange}
                value={formData.end_time}
                isInvalid={!!formError.end_time}
              />
              <div className="invalid-feedback d-block">
                {formError.end_time}
              </div>
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label>Call Agenda</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                name="description"
                onChange={handleFieldChange}
                value={formData.description}
                isInvalid={!!formError.description}
              />
              <Form.Control.Feedback type="invalid">
                {formError.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Form.Group className="d-flex justify-content-end gap-1 border-top p-3">
        <SaveButton handleSubmit={handleSubmit} onSave={onSave} />
        {!onSave && (
          <BackButton variant={"danger"} size={"sm"}>
            Cancel
          </BackButton>
        )}
      </Form.Group>
    </>
  );
};
AddCalls.prototype = {
  handleFieldChange: PropTypes.func,
  formData: PropTypes.any,
  formError: PropTypes.any,
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
  itemId: PropTypes.string,
};
export default AddCalls;
