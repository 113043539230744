import { React, useContext, useEffect, useState } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  Tab,
  Tabs,
  Row,
  Col,
  Badge,
} from "react-bootstrap";

import {
  FaBuilding,
  FaEnvelopeSquare,
  FaInstalod,
  FaPhoneSquareAlt,
  FaUser,
} from "react-icons/fa";
import useAxisproPermission from "hooks/useAxisproPermission";
import axios from "axios";
import LeadStage from "./Overview/LeadStage";
import {
  MdAttachFile,
  MdBusinessCenter,
  MdInfoOutline,
  MdOutlineDateRange,
  MdOutlineIncompleteCircle,
  MdPriorityHigh,
  MdSource,
} from "react-icons/md";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Contacts from "module/common/ViewPages/Common/Contacts/Contacts";
import CommonAttachments from "module/common/ViewPages/Common/Attachments/CommonAttachments";
import Calls from "module/common/ViewPages/Common/Calls/Calls";
import Meetings from "module/common/ViewPages/Common/Meetings/Meetings";
import Activity from "./Overview/Activity";
import { RxActivityLog } from "react-icons/rx";
import { IoMdContact, IoMdPeople } from "react-icons/io";
import { IoCallSharp } from "react-icons/io5";
import {
  ConvertButton,
  CustomBackButton,
  CustomEditButton,
  RedirectButton,
} from "module/common/Buttons/AppButtons";
import { SiGoogleadmob, SiPrefect } from "react-icons/si";
import ConvertModal from "./Overview/Modals/ConvertModal";
import { AuthWizardContext } from "context/Context";
import { BsFillGeoAltFill } from "react-icons/bs";
import ListLoading from "module/common/ListLoading";

function LeadOffcanvas({ show, id, onHide }) {
  const { user } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  let { overviewId } = useParams();
  const [key, setKey] = useState("info");
  const [leadData, setLeadData] = useState({});
  const [loading, setLoading] = useState(false);
  const axisProPermission = useAxisproPermission();
  overviewId = overviewId ? overviewId : id ? id : "";
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const isDataLoaded = Object.keys(leadData).length > 0;
  const fetchData = async () => {
    setLoading(true);
    axios
      .get(`crm/lead/${overviewId}`)
      .then((res) => {
        if (res.data.success) {
          setLeadData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (overviewId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId, id]);

  const handleOnHide = () => {
    if (show) {
      onHide();
      setKey("info")
    } else {
      navigate(-1);
      setKey("info")
    }
  };

  return (
    <Offcanvas
      show={show ? show : overviewId ? true : false}
      onHide={handleOnHide}
      placement="end"
      style={{
        width: "600px",
      }}
    >
      <ConvertModal data={leadData} />

      <>
        <Offcanvas.Header className="border-bottom">
          <Offcanvas.Title className="d-flex flex-wrap justify-content-between align-items-center gap-2 w-100">
            <div
              className="d-flex align-items-center gap-2"
              style={{ maxWidth: "66%" }}
            >
              <span
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  height: "18px",
                }}
                className="fs-1"
              >
                {leadData.title}
              </span>
              <div className="me-2">
                <RedirectButton
                  title={"Lead Overview"}
                  route={`/lead/info/${overviewId}?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                />
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center">
              {leadData.is_converted ? (
                <Badge className="fs--1 me-1" bg="success p-2">
                  Converted
                </Badge>
              ) : (
                <>
                  {axisProPermission("convert-lead-to-opportunity") && (
                    <ConvertButton
                      className={"me-1"}
                      disabled={
                        leadData.is_converted && isDataLoaded ? true : false
                      }
                      onClick={() =>
                        navigate(
                          `?${createSearchParams({
                            ...allQueryParams,
                            convert: true,
                          })}`
                        )
                      }
                    />
                  )}
                </>
              )}
              {axisProPermission("update-lead") && (
                <CustomEditButton
                  variant="light"
                  onClick={() => {
                    navigate(
                      `/lead/list?${createSearchParams({
                        ...allQueryParams,
                      })}${
                        filterItems || pageNumber ? "&" : ""
                      }mode=edit&id=${overviewId}`
                    );
                  }}
                  label="Edit Task"
                  className="shadow me-1"
                  disabled={isDataLoaded ? false : true}
                />
              )}
              <CustomBackButton
                variant="light"
                title="Back"
                onClick={handleOnHide}
                className={"shadow"}
              />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 d-flex flex-column">
          {Object.keys(leadData).length > 0 && !loading ? (
            <>
              <div
                className="border-bottom"
                style={{ backgroundColor: "#eff5fd" }}
              >
                <Row className="gy-3 gx-2">
                  <Col xs={6} md={4}>
                    <LeadStage
                      data={
                        <Badge bg="danger" className="fw-bold">
                          {leadData?.reference}
                        </Badge>
                      }
                      label={"Reference"}
                      icon={
                        <SiPrefect
                          size={17}
                          className="me-2 text-mute"
                          color="#2ab3a8"
                        />
                      }
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <LeadStage
                      data={leadData.is_company ? "Company" : "Individual"}
                      label={"Lead Type"}
                      icon={
                        <MdOutlineIncompleteCircle
                          size={17}
                          className="me-2 text-mute"
                          color="#0A6847"
                        />
                      }
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <LeadStage
                      data={leadData.lead_source_name}
                      label={"Lead Source"}
                      icon={
                        <MdSource
                          size={17}
                          className="me-2 text-mute"
                          color="#3FA2F6"
                        />
                      }
                    />
                  </Col>
                  <Col xs={6} md={4} className="mt-0">
                    <LeadStage
                      data={leadData.lead_status}
                      label={"Lead Status"}
                      icon={
                        <FaInstalod
                          size={17}
                          className="me-2 text-mute"
                          color="#FFA38F"
                        />
                      }
                    />
                  </Col>
                  <Col className="mt-0" xs={6} md={4}>
                    <LeadStage
                      data={leadData.lead_priority}
                      label={"Lead Priority"}
                      icon={
                        <MdPriorityHigh
                          size={17}
                          className="me-2 text-mute"
                          color="#E4003A"
                        />
                      }
                    />
                  </Col>
                  <Col className="mt-0" xs={6} md={4}>
                    <LeadStage
                      data={leadData.business_current_status}
                      label={"Business Current Status"}
                      icon={
                        <MdBusinessCenter
                          size={17}
                          className="me-2 text-mute"
                          color="#FFB22C"
                        />
                      }
                    />
                  </Col>
                </Row>
              </div>
              <Tabs
                defaultActiveKey="info"
                className="custom-lead-offcanvas-header-tabs mt-2"
                variant="tabs"
                justify
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab
                  eventKey="info"
                  title={
                    <div className="d-flex align-items-center">
                      <MdInfoOutline size={15} color="#3FA2F6" />
                      <span className="ms-1">Info</span>
                    </div>
                  }
                >
                  <ListGroup className="rounded-0">
                    <ListGroupItem
                      style={{ color: "black" }}
                      className="p-3 p-3 pt-2 pb-2 rounded-0 overview-details-title"
                    >
                      <SiGoogleadmob size={16} className="mb-1 me-1" /> LEAD
                      INFORMATION
                    </ListGroupItem>
                    <div className="p-2 ps-3 pe-3">
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pb-2 pt-1 "
                      >
                        <FaUser size={16} className="me-1" color="#508D4E" />
                        Lead Owner :{" "}
                        <span className="text-dark fw-bold">
                          {leadData.lead_owner_name
                            ? leadData.lead_owner_name
                            : "N/A"}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pt-1 pb-2"
                      >
                        <FaPhoneSquareAlt
                          size={16}
                          className="me-1"
                          color="#433D8B"
                        />
                        Mobile Number :{" "}
                        <span className="text-dark fw-bold">
                          {leadData.phone_no ? leadData.phone_no : "N/A"}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pt-1 pb-2"
                      >
                        <FaEnvelopeSquare
                          size={16}
                          className="me-1"
                          color="#88D66C"
                        />
                        Email Address :{" "}
                        <span className="text-dark fw-bold">
                          {leadData.email ? leadData.email : "N/A"}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pt-1 pb-2"
                      >
                        <FaBuilding
                          size={16}
                          className="me-1"
                          color="#3FA2F6"
                        />
                        Company Name :{" "}
                        <span className="text-dark fw-bold">
                          {leadData.company_name
                            ? leadData.company_name
                            : "N/A"}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pt-1 pb-2"
                      >
                        <FaBuilding
                          size={16}
                          className="me-1"
                          color="#FFA823"
                        />
                        Industry :{" "}
                        <span className="text-dark fw-bold">
                          {leadData.industry ? leadData.industry : "N/A"}
                        </span>
                      </ListGroupItem>
                      {user?.enable_lead_geo_tagging && (
                        <ListGroupItem
                          style={{ color: "black" }}
                          className="border-0 ps-0 pt-1 pb-2"
                        >
                          <BsFillGeoAltFill
                            size={16}
                            className="me-1 mb-1"
                            color="#E72929"
                          />
                          Geo Tag :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.geo_tag_text
                              ? leadData.geo_tag_text
                              : "N/A"}
                          </span>
                        </ListGroupItem>
                      )}
                    </div>
                  </ListGroup>
                  <ListGroup className="mt-3">
                    <ListGroupItem
                      style={{ color: "black" }}
                      className="p-3 p-3 pt-2 pb-2 rounded-0 overview-details-title"
                    >
                      <IoMdContact size={18} className="mb-1 me-1" />
                      PRIMARY CONTACT INFORMATION
                    </ListGroupItem>
                    <div className="p-2 ps-3 pe-3">
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pb-2 pt-1"
                      >
                        <FaUser size={16} className="me-1" color="#E90074" />
                        Name :{" "}
                        <span className="text-dark fw-bold">
                          {leadData?.primary_contact[0]?.full_name
                            ? leadData.primary_contact[0]?.full_name
                            : "N/A"}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pt-1 pb-2"
                      >
                        <FaEnvelopeSquare
                          size={16}
                          className="me-1"
                          color="#B692C2"
                        />
                        Email Address :{" "}
                        <span className="text-dark fw-bold">
                          {leadData?.primary_contact[0]?.email
                            ? leadData?.primary_contact[0]?.email
                            : "N/A"}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        style={{ color: "black" }}
                        className="border-0 ps-0 pb-1 pt-1 pb-2"
                      >
                        <FaPhoneSquareAlt
                          size={16}
                          className="me-1"
                          color="#FFA38F"
                        />
                        Contact Number :{" "}
                        <span className="text-dark fw-bold">
                          {leadData?.primary_contact[0]?.phone_no
                            ? leadData?.primary_contact[0]?.phone_no
                            : "N/A"}
                        </span>
                      </ListGroupItem>
                    </div>
                  </ListGroup>
                </Tab>
                {axisProPermission("list-lead-contact") && (
                  <Tab
                    eventKey="contacts"
                    title={
                      <div className="d-flex align-items-center">
                        <IoMdContact size={14} color="#FFA38F" />
                        <span className="ms-1">Contacts</span>
                      </div>
                    }
                  >
                    {key === "contacts" ? (
                      <Contacts
                        type="LEAD"
                        itemId={overviewId}
                        overview={"lead"}
                      />
                    ) : null}
                  </Tab>
                )}
                {axisProPermission("list-lead-call") && (
                  <Tab
                    eventKey="calls"
                    title={
                      <div className="d-flex align-items-center">
                        <IoCallSharp size={14} color="#E4003A" />{" "}
                        <span className="ms-1">Calls</span>
                      </div>
                    }
                  >
                    {key === "calls" ? (
                      <Calls
                        type="LEAD"
                        itemId={overviewId}
                        overview={"lead"}
                      />
                    ) : null}
                  </Tab>
                )}
                {axisProPermission("list-lead-meeting") && (
                  <Tab
                    eventKey="meeting"
                    title={
                      <div className="d-flex align-items-center">
                        <IoMdPeople size={14} color="#FFB22C" />{" "}
                        <span className="ms-1">Meeting</span>
                      </div>
                    }
                  >
                    {key === "meeting" ? (
                      <Meetings
                        type="LEAD"
                        itemId={overviewId}
                        overview={"lead"}
                      />
                    ) : null}
                  </Tab>
                )}
                <Tab
                  eventKey="attachment"
                  title={
                    <div className="d-flex align-items-center">
                      <MdAttachFile size={14} color="#3FA2F6" />{" "}
                      <span className="ms-1">Attachment</span>
                    </div>
                  }
                >
                  {key === "attachment" ? (
                    <CommonAttachments
                      type="LEAD"
                      itemId={overviewId ? overviewId : id ? id : ""}
                      overview={"lead"}
                    />
                  ) : null}
                </Tab>
                <Tab
                  eventKey="activity"
                  title={
                    <div className="d-flex align-items-center">
                      <RxActivityLog size={12} color="#B692C2" />
                      <span className="ms-2">Activity</span>
                    </div>
                  }
                >
                  {key === "activity" ? <Activity itemId={overviewId} /> : null}
                </Tab>
              </Tabs>
            </>
          ) : loading ? (
            <div className="d-flex h-100 justify-content-center align-items-center">
              <ListLoading loadingText="Fetching Data..." className="h-100" />
            </div>
          ) : (
            <div className="d-flex h-100 justify-content-center align-items-center">
              <span>No User Data Available</span>
            </div>
          )}
          {key === "info" && (
            <Card.Footer className="pb-0 mt-auto mb-3 border-top">
              <div className="d-flex mt-2 justify-content-between">
                <div className="d-flex custom-text-muted">
                  <FaUser size={14} color="#3FA2F6" style={{ marginTop: 2 }} />
                  <div className="d-flex ms-1">
                    <span className="me-1">Created by :</span>
                    <span className="ms-1  fw-bold">
                      {leadData?.created_user_full_name
                        ? leadData.created_user_full_name
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="d-flex custom-text-muted">
                  <MdOutlineDateRange
                    size={17}
                    color="#FFA38F"
                    style={{ marginTop: 1 }}
                  />
                  <div className="ms-1">
                    <span className="">Created at :</span>
                    <span className="ms-1 fw-bold">
                      {leadData?.created_at ? leadData.created_at : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </Card.Footer>
          )}
        </Offcanvas.Body>
      </>
    </Offcanvas>
  );
}

export default LeadOffcanvas;
