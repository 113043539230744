import { React, useContext, useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Badge, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";

import { FaCircle, FaUserEdit } from "react-icons/fa";
import { TbEditCircle } from "react-icons/tb";
import { BiTask } from "react-icons/bi";

import {
  AuthWizardContext,
  DeleteContext,
  StoreContext,
} from "context/Context";
import {
  AddButton,
  CloneButton,
  DeleteButton,
  EditButton,
  TimerActionButton,
} from "module/common/Buttons/AppButtons";
import {
  getObjectFromLocalStorage,
  removeHtmlTagsFromString,
  setObjectInLocalStorage,
} from "helpers/utils.js";
import { apiCall } from "helpers/apiCalls";
import { commonTaskFilterFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import { MODULES } from "constants/Constants";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import ExportButton from "components/export-button/ExportButton";
import CommonFilter from "components/filter/CommonFilter";
import removeRefData from "helpers/removeRefData";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import AddAssigneeModal from "../TaskDashboard/Common/AddAssigneeModal";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import TimerConfirm from "module/common/Timer/TimerConfirm";
// import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import CustomizeColumnButton from "module/common/Buttons/CustomizeColumnButton";
import RefreshButton from "components/refresh-button/RefreshButton";
import TableHeader from "helpers/tableHeader";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import CollaboratorsListDropDown from "./CollaboratorsListDropDown";
import BulkUpdateModal from "./BulkUpdateModal";
import TaskInfo from "../TaskInfo/TaskInfo";
import TableOverlayTrigger from "components/table-overlay-trigger/TableOverlayTrigger";
import CommonStatusUpdateDropDown from "module/common/ViewPages/Common/Meetings/CommonStatusUpdateDropDown";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";

const TaskTable = ({
  mainCardClassName = "",
  renderFrom = "task_list",
  renderFromId,
}) => {
  let navigate = useNavigate();
  const Translate = useAxisproTranslate();
  let { user, dispatch: authDispatch } = useContext(AuthWizardContext);
  let loggedAsCustomer = user?.customer_id ? true : false;
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const { itemInfoId, client_id, empId } = useParams();
  const filterItems = queryParams.get("filters");
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState({});
  const dataProcessStatus =
    Object.keys(tasks).length > 0 && Object.keys(tasks.data).length > 0;
  const [userProfile, setUserProfile] = useState();
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const [taskInfoData, setTaskInfoId] = useState();
  const [showTaskInfo, setShowTaskInfo] = useState(false);
  // const [statusLoading, setStatusLoading] = useState(false);
  const [showRenderedContent, setShowRenderedContent] = useState(false);
  const commonSearchParams = createSearchParams({ ...allQueryParams });
  const {
    status_filter,
    date_range,
    from_date,
    to_date,
    date_range_for,
    project_id,
    // project_id_ref,
    coordinator,
    // coordinator_ref,
    sprint_id,
    // sprint_id_ref,
    assignee,
    // assignee_ref,
    priority,
    // priority_ref,
    created_by,
    // created_by_ref,
    task_no,
    title,
    unassigned,
    month,
    collaborator_id,
    product_id,
  } = allQueryParams;
  const filterParamsRef = useRef(allQueryParams);
  const [filterFields, setFilterFields] = useState(commonTaskFilterFormKeys);
  const { pathname: renderFromPath } = useLocation();
  const [customColumns, setCustomColumns] = useState([]);
  const { store, addIitemToStore } = useContext(StoreContext);
  const {
    [`CUSTOMIZED_COLUMNS_${MODULES.TASKS?.toUpperCase()}`]:
      customizedColumnsArray,
    // triggerFromQuickLinkTask,
  } = store;
  const [selectedTasksId, setSelectedTasksId] = useState([]);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);
  const permission = {
    store: GetAcodaxPermission("TASK", "create"),
    showAssigned: GetAcodaxPermission("AT", "view"),
    show: GetAcodaxPermission("TASK", "show"),
    showCreated: GetAcodaxPermission("CT", "view"),
    updateTaskAssignee: GetAcodaxPermission("TA", "update"),
    updateCreatedTask: GetAcodaxPermission("CT", "update"),
    update: GetAcodaxPermission("TASK", "update"),
    deleteCreatedTask: GetAcodaxPermission("CTO", "delete"),
    delete: GetAcodaxPermission("TASK", "delete"),
    export: GetAcodaxPermission("TASK", "export"),
    updateTaskStatus: GetAcodaxPermission("TS", "update"),
    showSprint: GetAcodaxPermission("SPRINT", "show"),
    showProject: GetAcodaxPermission("PROJECT", "show"),
  };
  const taskShowPermission =
    permission?.showAssigned || permission?.showCreated || permission?.show;
  const userBasedFilterVisibility =
    getObjectFromLocalStorage("filter")?.["taskFilter"] ?? false;
  const [mainFilterVisibility, setMainFilterVisibility] = useState(
    userBasedFilterVisibility
  );

  const isFromSprint = renderFrom === MODULES.SPRINT;
  const isFromProject = renderFrom === MODULES.PROJECT;
  const isFromAccount = renderFrom === MODULES.ACCOUNT;
  const isFromEmployee = renderFrom === MODULES.EMPLOYEE;
  const isNotFromTask =
    isFromProject || isFromSprint || isFromAccount || isFromEmployee;
  const withoutProject = isFromProject || isFromSprint;
  const activeRoute =
    renderFrom === "task_list"
      ? "/tasks/list"
      : isFromProject
      ? `/project/info/${itemInfoId}`
      : isFromSprint
      ? `/sprints/info/${itemInfoId}`
      : isFromAccount
      ? `/account/overview/${client_id}`
      : isFromEmployee
      ? `/employee/overview/${empId}`
      : "";

  const hasPermissionToEdit = (loggedInUserId, createdUserId) => {
    const canUpdateTask = permission.update;
    const canUpdateCreateTask = permission.updateCreatedTask;

    if (canUpdateTask) {
      return true;
    }
    if (canUpdateCreateTask && loggedInUserId === createdUserId) {
      return true;
    }
    return false;
  };

  const hasPermissionToDelete = (loggedInUserId, createdUserId) => {
    const canDeleteTask = permission.delete;
    const canDeleteCreateTask = permission.deleteCreatedTask;

    if (canDeleteTask) {
      return true;
    }
    if (canDeleteCreateTask && loggedInUserId === createdUserId) {
      return true;
    }
    return false;
  };

  const hasPermissionToStatusUpdate = (loggedInUserId, assignedUserId) => {
    const canUpdateTaskStatus = permission.updateTaskStatus;

    if (canUpdateTaskStatus) {
      return true;
    }
    if (loggedInUserId === assignedUserId) {
      return true;
    }
    return false;
  };

  const [assigneeUpdateObj, setAssigneeUpdateObj] = useState({
    status: false,
    assigneeId: null,
    assigneeName: null,
    task_id: null,
  });
  const [loadingTimer, setLoadingTimer] = useState(false);
  const [show, setShow] = useState(false);
  const [timerRunningTask, setTimerRunningTask] = useState({});
  const handleClose = () => {
    setShow(false);
    setTimerRunningTask({});
  };
  const handleTimerConfirm = (task) => {
    setShow(true);
    setTimerRunningTask(task);
  };

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/task/" + id,
        title: "Delete Task",
        message: "Are you sure you want to delete this task?",
        onSuccess: async () => {
          let data = await getApiData(pageNumber);
          setTasks(data);
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const getApiData = async (page, filterParams) => {
    const statusFilter = filterParams
      ? filterParams?.status_filter
        ? filterParams?.status_filter
        : null
      : status_filter;
    const statusFilterTemp = statusFilter ? statusFilter.split("/") : [];
    const productIdFilter = product_id ? product_id?.split("/") : [];

    const dynamicFilterTemp = filterItems
      ? removeRefData(createFilterArray(filterItems), "array")
      : null;

    const defaultFilterParams = filterParams
      ? {
          status_filter: statusFilterTemp,
          project_id: filterParams?.project_id,
          coordinator: filterParams?.coordinator,
          sprint_id: filterParams?.sprint_id,
          assignee: filterParams?.assignee,
          priority: filterParams?.priority,
          created_by: filterParams?.created_by,
          task_no: filterParams?.task_no,
          title: filterParams?.title,
          date_range_for: filterParams?.date_range_for,
          from_date: filterParams?.from_date,
          to_date: filterParams?.to_date,
          unassigned: filterParams?.unassigned,
          collaborator_id: filterParams?.collaborator_id,
          product_id: productIdFilter,
        }
      : {
          status_filter: statusFilterTemp,
          project_id: project_id,
          coordinator: coordinator,
          sprint_id: sprint_id,
          assignee: assignee,
          priority: priority,
          created_by: created_by,
          task_no: task_no,
          title: title,
          date_range_for: date_range_for,
          from_date: from_date,
          to_date: to_date,
          unassigned: unassigned,
          collaborator_id: collaborator_id,
          product_id: productIdFilter,
        };

    const filterParamObject = {
      ...defaultFilterParams,
      page: page || pageNumber,
      filters: dynamicFilterTemp,
      requested_from: renderFrom,
    };

    const exportParamObject = {
      status_filter: statusFilterTemp,
      project_id: project_id,
      coordinator: coordinator,
      sprint_id: sprint_id,
      assignee: assignee,
      priority: priority,
      created_by: created_by,
      task_no: task_no,
      title: title,
      date_range_for: date_range_for,
      from_date: from_date,
      to_date: to_date,
      unassigned: unassigned,
      collaborator_id: collaborator_id,
      product_id: productIdFilter,
    };
    setFilterFields(exportParamObject);
    filterParamObject.requested_from = renderFrom;
    if (isFromProject) {
      filterParamObject.project_id = itemInfoId;
    } else if (isFromSprint) {
      filterParamObject.sprint_id = itemInfoId;
    } else if (isFromAccount) {
      filterParamObject.account_id = client_id;
    } else if (isFromEmployee) {
      filterParamObject.employee_id = renderFromId;
    }
    const data = await apiCall({
      url: "crm/task",
      params: filterParamObject,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return data;
  };

  const getData = async (page, isLoadingEnable = true, filterParams) => {
    isLoadingEnable && setIsLoading(true);
    try {
      let data = await getApiData(page, filterParams);
      if (data?.data?.length < 1 && pageNumber > 1) {
        navigate(
          `${activeRoute}?${createSearchParams({
            ...allQueryParams,
            page: pageNumber - 1,
          })}`
        );
        data = await getApiData(pageNumber - 1, filterParams);
      }

      setTasks(data);
      setSelectedTasksId([]);
      isLoadingEnable && setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const inIt = async () => {
      try {
        if (!renderFromPath.includes("tasks/list/overview")) {
          setIsLoading(true);
          getData(pageNumber);
        }
      } catch (error) {
        console.log(error);
      }
    };
    inIt();

    return () => {
      setShowTaskInfo(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    status_filter,
    date_range_for,
    project_id,
    coordinator,
    sprint_id,
    assignee,
    priority,
    created_by,
    task_no,
    title,
    date_range,
    unassigned,
    month,
    to_date,
    from_date,
    // triggerFromQuickLinkTask,
    collaborator_id,
    product_id,
  ]);

  // useEffect(() => {
  //   const statusFilterTemp = status_filter ? status_filter.split("/") : [];
  //   setFilterFields((previous) => ({
  //     ...previous,
  //     status_filter: statusFilterTemp,
  //     status_filter_ref: statusFilterTemp.map((item) => ({
  //       label: item.replace("_", " "),
  //       value: item,
  //     })),
  //     project_id: project_id ? project_id : "",
  //     project_id_ref: project_id ? JSON.parse(project_id_ref) : "",
  //     coordinator: coordinator ? coordinator : "",
  //     coordinator_ref: coordinator ? JSON.parse(coordinator_ref) : "",
  //     sprint_id: sprint_id
  //       ? sprint_id
  //       : sprintData?.value
  //       ? sprintData?.value
  //       : "",
  //     sprint_id_ref: sprint_id
  //       ? JSON.parse(sprint_id_ref)
  //       : sprintData?.value
  //       ? {
  //           value: sprintData?.value,
  //           label: sprintData?.label,
  //         }
  //       : "",
  //     assignee: assignee ? assignee : "",
  //     assignee_ref: assignee ? JSON.parse(assignee_ref) : "",
  //     priority: priority ? priority : "",
  //     priority_ref: priority
  //       ? JSON.parse(priority_ref)
  //       : { label: "", value: "" },
  //     created_by_ref: created_by ? JSON.parse(created_by_ref) : "",
  //     task_no: task_no ? task_no : "",
  //     title: title ? title : "",
  //     date_range: date_range ? date_range : "",
  //     date_range_for: date_range_for ? date_range_for : "",
  //     from_date: from_date ? from_date : "",
  //     to_date: to_date ? to_date : "",
  //     unassigned: unassigned ? parseInt(unassigned) : "",
  //     collaborator_id: collaborator_id,
  //   }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [status_filter, sprintData?.value]);

  const handleUserProfileArea = (id) => {
    if (id) {
      setUserProfile(id);
      setShowUserProfileArea(true);
    } else {
      setUserProfile();
      setShowUserProfileArea(false);
    }
  };

  const handleTaskInfo = (data) => {
    if (data?.id) {
      setTaskInfoId(data?.id);
      setShowTaskInfo(true);
    } else {
      setTaskInfoId();
      setShowTaskInfo(false);
    }
  };

  const handleTaskInfoClose = () => {
    setShowTaskInfo(false);
    delete allQueryParams?.info_tab;
    navigate(
      `?${createSearchParams({
        ...allQueryParams,
      })}`
    );
  };

  const handleAssigneeUpdate = (id, task_id, name) => {
    setAssigneeUpdateObj({
      status: true,
      assigneeId: id,
      task_id: task_id,
      assigneeName: name,
    });
  };

  const handleCloseAssigneeUpdate = () => {
    setAssigneeUpdateObj({
      status: false,
      assigneeId: "",
      task_id: "",
      assigneeName: "",
    });
  };

  const handleCloseBulkModal = () => {
    setShowBulkUpdateModal(false);
  };

  const handleTimerError = (error) => {
    if (error.response.data && error.response.data.message) {
      const validation_error =
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.errors
          ? error.response.data.data.errors
          : null;
      showToast(validation_error.task_id[0]);
    } else {
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    }
    setLoadingTimer(false);
  };

  const handleTimer = async () => {
    setLoadingTimer(true);
    try {
      const startOrStopTimerUrl = timerRunningTask?.running_status
        ? `crm/stop-timer/${timerRunningTask?.id}`
        : `crm/start-timer/${timerRunningTask?.id}`;

      const response = await axios.post(startOrStopTimerUrl);
      const { data } = response;
      if (data.success === true) {
        // const runningTasksData = await axios.get("crm/running-tasks-info");
        // const runningTasks = runningTasksData.data;
        // if (runningTasks.success === true) {
        let updatedData = {
          ...user,
          running_tasks_count: timerRunningTask?.running_status ? 0 : 1,
          running_task_id: timerRunningTask?.id,
          // running_tasks_count: runningTasks?.data?.count,
          // running_task_id: data?.data?.task_id,
        };
        authDispatch({ type: "UPDATE", payload: { ...updatedData } });
        handleClose();
        afterStatusChange("in_progress", timerRunningTask?.id);
        // setTimeout(() => {
        //   handleClose();
        // }, 300);
        // } else {
        //   showToast(
        //     `Something went wrong, please refresh the page and try again.`,
        //     "error"
        //   );
        // }
        setLoadingTimer(false);
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
        setLoadingTimer(false);
      }
    } catch (error) {
      handleClose();
      handleTimerError(error);
    }
  };

  useEffect(() => {
    if (dataProcessStatus) {
      setTasks((prevState) => ({
        ...prevState,
        data: tasks.data.map((task) => {
          if (task?.id === user?.running_task_id) {
            return {
              ...task,
              running_status: user?.running_tasks_count > 0 ? true : false,
            };
          }
          return task;
        }),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleClone = (taskId) => {
    navigate(
      `${
        renderFrom === "task_list"
          ? `clone/${taskId}`
          : isFromProject
          ? `/project/info/${itemInfoId}/task/clone/${taskId}`
          : isFromSprint
          ? `/sprints/info/${itemInfoId}/task/clone/${taskId}`
          : isFromAccount
          ? `/account/overview/${client_id}/task/clone/${taskId}`
          : isFromEmployee
          ? `/employee/overview/${empId}/task/clone/${taskId}`
          : ""
      }?${createSearchParams(allQueryParams)}`
    );
  };
  const handleSelectAll = (e) => {
    const allTaskIds = tasks.data.map((task) => task.id);
    if (e.target.checked) {
      setSelectedTasksId(allTaskIds);
    } else {
      setSelectedTasksId([]);
    }
  };

  const handleRowSelect = (taskId) => {
    setSelectedTasksId((prevSelected) =>
      prevSelected.includes(taskId)
        ? prevSelected.filter((id) => id !== taskId)
        : [...prevSelected, taskId]
    );
  };

  const afterStatusChange = (status, id, oldStatus) => {
    if (tasks?.data?.length > 0 && status) {
      const statusArray = status_filter.split("/");
      let formattedStatus = status
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      setTasks((prevTasks) => {
        const updatedItems = prevTasks.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              status: status,
              status_formatted: formattedStatus,
              old_status: oldStatus ?? "",
            };
          } else {
            return { ...item };
          }
        });
        const filteredItems = updatedItems.filter((item) =>
          statusArray.includes(item.status)
        );
        return {
          ...prevTasks,
          data: filteredItems,
          meta: {
            ...prevTasks.meta,
            to: filteredItems?.length,
            total:
              prevTasks?.data?.length > filteredItems?.length
                ? prevTasks?.meta?.total - 1
                : prevTasks?.meta?.total,
          },
        };
      });
    }
  };

  const afterAssigneeChange = (id, assignee_ref) => {
    if (tasks?.data?.length > 0) {
      if (assignee) {
        setTasks((prevTasks) => {
          const updatedItems = prevTasks.data.filter((item) => item?.id !== id);
          return {
            ...prevTasks,
            data: updatedItems,
            meta: {
              ...prevTasks.meta,
              to: updatedItems?.length,
              total: prevTasks?.meta?.total - 1,
            },
          };
        });
      } else {
        if (assignee_ref) {
          setTasks((prevTasks) => {
            const updatedItems = prevTasks.data.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  assigned_to: assignee_ref?.value ?? "",
                  assignee_full_name: assignee_ref?.label ?? "",
                  assignee: assignee_ref,
                };
              } else {
                return { ...item };
              }
            });
            return {
              ...prevTasks,
              data: updatedItems,
            };
          });
        }
      }
    }
  };

  const updateTimerStatus = (id, event) => {
    setTasks((prevTasks) => {
      const updatedItems = prevTasks.data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            running_status: event === "start" ? true : false,
          };
        } else {
          return { ...item };
        }
      });
      return {
        ...prevTasks,
        data: updatedItems,
      };
    });
  };

  const columns = [
    {
      order: 0,
      width: "1%",
      accessor: "select",
      disableSortBy: true,
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <input
          type="checkbox"
          onClick={handleSelectAll}
          checked={selectedTasksId.length === tasks.data.length}
          {...getToggleAllRowsSelectedProps()}
        />
      ),
      Cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedTasksId.includes(row.original.id)}
          onClick={() => handleRowSelect(row.original.id)}
          {...row.getToggleRowSelectedProps()}
        />
      ),
    },
    {
      order: 1,
      width: "5%",
      isMandatory: true,
      accessor: "task_number",
      Header: Translate("TASK NO"),
      headerProps: {
        className: `pe-1`,
      },
      cellProps: {
        className: `py-0`,
      },
      retrieveData: taskShowPermission && handleTaskInfo,
      Cell: (rowData) => {
        const { task_number } = rowData.row.original;
        return <>#{task_number}</>;
      },
    },
    {
      width: "10%",
      order: 2,
      isMandatory: true,
      accessor: "title",
      Header: Translate("TASK TITLE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "15rem" },
      },
      retrieveData: taskShowPermission && handleTaskInfo,
      Cell: (rowData) => {
        const { title, description } = rowData.row.original;
        return (
          <div className="d-flex flex-column text-nowrap">
            <span className="text-dark" title={title}>
              {title?.length > 35 ? `${title?.slice(0, 35)}...` : title}
            </span>
            <div className="fs--2 text-wrap">
              <div
                className="setInnerHtml"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "15rem",
                }}
              >
                {removeHtmlTagsFromString(description)}
              </div>
            </div>
          </div>
        );
      },
    },
    ...(withoutProject || loggedAsCustomer
      ? []
      : [
          {
            width: "5%",
            order: 3,
            accessor: "project_title",
            Header: Translate("PROJECT"),
            headerProps: { className: "pe-1" },
            cellProps: {
              className: "py-0",
              style: {
                minWidth: "10rem",
              },
            },
            customRouteTo: permission?.showProject && {
              route: `/project/info/routeId?tab=overview`,
              key: "project_id",
              islocallyStored: true,
            },
            Cell: (rowData) => {
              const { project_title, id } = rowData.row.original;
              return (
                <TableOverlayTrigger
                  content={project_title}
                  id={id}
                  length={15}
                />
              );
            },
          },
        ]),
    ...(isFromSprint || loggedAsCustomer
      ? []
      : [
          {
            width: "5%",
            order: 4,
            accessor: "sprint_name",
            Header: Translate("SPRINT"),
            headerProps: { className: "pe-1" },
            cellProps: {
              className: "py-0",
              style: {
                minWidth: "10rem",
              },
            },
            customRouteTo: permission?.showSprint && {
              route: `/sprints/info/routeId?tab=tasklist&${createSearchParams({
                status_filter: defaultTaskStatusFilter.join("/"),
                status_filter_ref: JSON.stringify(
                  defaultTaskStatusFilter.map((item) => ({
                    label: item?.replaceAll("_", " "),
                    value: item,
                  }))
                ),
              })}`,
              key: "sprint_id",
              islocallyStored: isFromProject ? true : false,
            },
            Cell: (rowData) => {
              const { sprint_name, id } = rowData.row.original;
              return (
                <TableOverlayTrigger
                  content={sprint_name}
                  id={id}
                  length={15}
                />
              );
            },
          },
        ]),
    ...(loggedAsCustomer
      ? []
      : [
          {
            width: "5%",
            order: 5,
            accessor: "assignee",
            Header: Translate("ASSIGNEE"),
            headerProps: { className: "pe-1" },
            cellProps: {
              className: "py-0",
              style: {
                minWidth: "12rem",
              },
            },
            Cell: (rowData) => {
              const { assignee, id } = rowData.row.original;
              return (
                <>
                  <div className="d-flex align-items-center gap-1">
                    {permission?.updateTaskAssignee && (
                      <Button
                        size="sm"
                        variant="transparent"
                        className="custom-button-action p-0"
                        onClick={() => {
                          handleAssigneeUpdate(
                            assignee?.id,
                            id,
                            assignee?.name
                          );
                        }}
                        title="Edit Assignee"
                      >
                        <FaUserEdit
                          size={15}
                          className="ms-1"
                          color="#bda49a"
                        />
                      </Button>
                    )}
                    <div
                      className="cursor-pointer text-uppercase"
                      onClick={() => {
                        if (assignee?.id) {
                          handleUserProfileArea(assignee?.id);
                        }
                      }}
                    >
                      <TableOverlayTrigger
                        content={assignee?.name ?? ""}
                        id={id}
                        length={15}
                      />
                    </div>
                  </div>
                </>
              );
            },
          },
        ]),
    {
      width: "10%",
      order: 10,
      accessor: "status",
      Header: Translate("STATUS"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      Cell: (rowData) => {
        const { id, assignee, status, running_status } = rowData.row.original;
        return (
          <>
            <div>
              {hasPermissionToStatusUpdate(id, assignee?.id) ? (
                <CommonStatusUpdateDropDown
                  afterStatusChange={afterStatusChange}
                  activeStatus={status}
                  postParams={{ task_id: id }}
                  fetchUrl="crm/task-status"
                  method="post"
                  postUrl="crm/task-status/update"
                  width="170px"
                  disableAfterEffects
                  from="task"
                  isDisabled={running_status}
                  oldStatus={status}
                />
              ) : (
                <span
                  className="text-capitalize d-flex align-items-center"
                  style={{ width: "max-content" }}
                >
                  <FaCircle color={getTaskStatusWithProps(status)} size={10} />
                  <span className="ms-1">{status.replace("_", " ")}</span>
                </span>
              )}
            </div>
          </>
        );
      },
    },
    ...(loggedAsCustomer
      ? []
      : [
          {
            width: "5%",
            order: 6,
            accessor: "collaborators",
            Header: Translate("COLLABORATORS"),
            headerProps: { className: "pe-1" },
            cellProps: {
              className: "py-0",
            },
            Cell: (rowData) => {
              const { collaborators, id } = rowData.row.original;
              return (
                <>
                  {collaborators?.length > 0 ? (
                    <CollaboratorsListDropDown
                      collaborators={collaborators}
                      taskId={id}
                    />
                  ) : (
                    <div className="d-flex align-items-center gap-1">-</div>
                  )}
                </>
              );
            },
          },
        ]),
    {
      width: "5%",
      order: 6,
      accessor: "product",
      Header: Translate("PRODUCTS"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "10rem" },
      },
      Cell: (rowData) => {
        const { product_name, id } = rowData.row.original;
        return (
          <>
            <TableOverlayTrigger content={product_name} id={id} length={16} />
          </>
        );
      },
    },
    {
      width: "5%",
      order: 7,
      accessor: "labels",
      Header: Translate("LABELS"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      retrieveData: taskShowPermission && handleTaskInfo,
      Cell: (rowData) => {
        const { labels } = rowData.row.original;
        return (
          <>
            <span className="d-flex flex-wrap gap-1">
              {Object.keys(labels).length > 0
                ? labels.map((labelItem, itemKey) => (
                    <OverlayTrigger
                      key={itemKey}
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Description : {labelItem.description ?? "N/A"}
                        </Tooltip>
                      }
                    >
                      <span
                        className="badge p-2 w-100"
                        style={{
                          backgroundColor: labelItem.colour,
                        }}
                      >
                        {labelItem.label}
                      </span>
                    </OverlayTrigger>
                  ))
                : "-"}
            </span>
          </>
        );
      },
    },
    {
      width: "5%",
      order: 8,
      accessor: "start_date_formatted",
      Header: Translate("START DATE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "10rem" },
      },
      retrieveData: taskShowPermission && handleTaskInfo,
      Cell: (rowData) => {
        const { start_date_formatted } = rowData.row.original;
        return (
          <span className="fs--1">
            {start_date_formatted ? start_date_formatted : "-"}
          </span>
        );
      },
    },
    {
      width: "5%",
      order: 9,
      accessor: "end_date_formatted",
      Header: Translate("END DATE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "10rem" },
      },
      retrieveData: taskShowPermission && handleTaskInfo,
      Cell: (rowData) => {
        const { end_date_formatted } = rowData.row.original;
        return (
          <>
            <span className="fs--1">
              {end_date_formatted ? end_date_formatted : "-"}
            </span>
          </>
        );
      },
    },
    ...(loggedAsCustomer
      ? []
      : [
          {
            width: "5%",
            order: 11,
            accessor: "priority",
            Header: Translate("PRIORITY"),
            headerProps: { className: "pe-1" },
            cellProps: {
              className: "py-0",
            },
            retrieveData: taskShowPermission && handleTaskInfo,
            Cell: (rowData) => {
              const { priority } = rowData.row.original;
              return (
                <>
                  {priority ? (
                    <Badge
                      className="fs--2"
                      bg="none"
                      style={{
                        backgroundColor: getPriorityWithProps(priority),
                        width: "60px",
                      }}
                    >
                      {priority}
                    </Badge>
                  ) : (
                    <span className="fs--1">-</span>
                  )}
                </>
              );
            },
          },
          {
            width: "5%",
            order: 12,
            accessor: "created_user_full_name",
            Header: Translate("CREATED BY"),
            headerProps: { className: "pe-1" },
            cellProps: {
              className: "py-0",
              style: { minWidth: "10rem" },
            },
            Cell: (rowData) => {
              const { created_user_full_name, created_by } =
                rowData.row.original;
              return (
                <>
                  <span
                    onClick={() => {
                      if (created_by) {
                        handleUserProfileArea(created_by);
                      }
                    }}
                    className="mb-0 fs--1 cursor-pointer"
                  >
                    <TableOverlayTrigger
                      content={created_user_full_name}
                      id={created_by}
                      length={15}
                    />
                  </span>
                </>
              );
            },
          },
        ]),
    {
      order: 13,
      accessor: "none",
      Header: "",
      headerProps: {
        className: `pe-1 custom-table-head-th-action`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action`,
      },
      Cell: (rowData) => {
        const {
          id,
          show_timer,
          running_status,
          created_by: created_user,
        } = rowData.row.original;
        return (
          <div className="major-div-element gap-2">
            {permission?.store && (
              <CloneButton
                className="p-0"
                variant="transparent"
                onClick={() => handleClone(id)}
                iconSize={16}
              />
            )}
            {show_timer && (
              <TimerActionButton
                margin="m-0"
                onClick={() => handleTimerConfirm(rowData.row.original)}
                timerStatus={running_status}
                iconSize={17}
              />
            )}
            {hasPermissionToEdit(user?.id, created_user) && (
              <EditButton
                margin="m-0"
                iconSize={16}
                route={
                  renderFrom === "task_list"
                    ? `edit/${id}?${commonSearchParams}`
                    : isFromProject
                    ? `/project/info/${itemInfoId}/task/edit/${id}?${commonSearchParams}`
                    : isFromSprint
                    ? `/sprints/info/${itemInfoId}/task/edit/${id}?${commonSearchParams}`
                    : isFromAccount
                    ? `/account/overview/${client_id}/task/edit/${id}?${commonSearchParams}`
                    : isFromEmployee
                    ? `/employee/overview/${empId}/task/edit/${id}?${commonSearchParams}`
                    : `edit/${id}?${commonSearchParams}`
                }
              />
            )}
            {hasPermissionToDelete(user?.id, created_user) && (
              <DeleteButton
                margin="m-0"
                iconSize={16}
                onClick={() => handleDelete(id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const canUpdateAllStatuses = tasks?.data
    ?.filter((task) => selectedTasksId.includes(task.id))
    .every((task) => hasPermissionToStatusUpdate(user?.id, task.assignee?.id));

  const getCustomizedColumnsData = () => {
    if (
      !Array.isArray(customizedColumnsArray) ||
      customizedColumnsArray.length === 0
    ) {
      return columns;
    }

    const customizedColumnAccessors = customizedColumnsArray.map((item) =>
      typeof item === "object" && item !== null ? item.accessor : item
    );

    return columns.filter((column) =>
      customizedColumnAccessors.includes(column.accessor)
    );
  };

  useEffect(() => {
    if (!isLoading) {
      setCustomColumns(getCustomizedColumnsData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizedColumnsArray, isLoading]);

  useEffect(() => {
    const existingFilter = getObjectFromLocalStorage("filter") || {};
    const updatedFilter = {
      ...existingFilter,
      taskFilter: mainFilterVisibility,
    };
    setObjectInLocalStorage("filter", updatedFilter);
  }, [mainFilterVisibility]);

  useEffect(() => {
    filterParamsRef.current = allQueryParams;
  }, [allQueryParams]);

  // useEffect(() => {
  //   if (user?.id && user?.branch_id) {
  //     const channelName = `app-broadcast-${user.branch_id}-${user.id}`;
  //     const channel = window.Echo.channel(channelName);
  //     channel.listen("AcodaxAppUserBroadCast", (settingsData) => {
  //       if (
  //         settingsData &&
  //         (settingsData?.type === "task_timer.stopped" ||
  //           settingsData?.type === "task_timer.started")
  //       ) {
  //         getData(
  //           filterParamsRef.current?.page,
  //           false,
  //           filterParamsRef.current
  //         );
  //       }
  //     });
  //     return () => {
  //       channel.stopListening("AcodaxAppUserBroadCast");
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.id, user?.branch_id]);

  useEffect(() => {
    if (Object.keys(tasks).length > 0) setShowRenderedContent(true);
    return () => setShowRenderedContent(false);
  }, [tasks]);

  useEffect(() => {
    if (store?.newTask && Object.keys(store?.newTask).length > 0) {
      if (assignee && assignee === store?.newTask?.assigned_to) {
        setTasks((prev) => ({
          ...prev,
          data:
            prev?.meta?.per_page > prev?.data?.length
              ? [store?.newTask, ...prev?.data]
              : [store?.newTask, ...prev?.data?.slice(0, -1)],
          meta: {
            ...prev.meta,
            to:
              prev?.meta?.per_page > prev?.data?.length
                ? prev?.data?.length + 1
                : prev?.meta?.to,
            total: prev?.meta?.total + 1,
          },
        }));
        addIitemToStore("newTask", []);
      } else if (!assignee) {
        setTasks((prev) => ({
          ...prev,
          data:
            prev?.meta?.per_page > prev?.data?.length
              ? [store?.newTask, ...prev?.data]
              : [store?.newTask, ...prev?.data?.slice(0, -1)],
          meta: {
            ...prev.meta,
            to:
              prev?.meta?.per_page >= prev?.data?.length
                ? prev?.data?.length + 1
                : prev?.meta?.to,
            total: prev?.meta?.total + 1,
          },
        }));
        addIitemToStore("newTask", []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.newTask]);

  return (
    <>
      <Outlet context={[getData]} />
      {!renderFromPath.includes("tasks/list/overview") && (
        <Card
          className={`flex-fill ${
            renderFrom === "task_list" && "mb-3"
          } ${mainCardClassName} ${
            isFromAccount || isFromEmployee ? "shadow-none" : ""
          }`}
        >
          {/* {statusLoading && <LoadingOverlay />} */}
          <TimerConfirm
            show={show}
            handleClose={handleClose}
            loading={loadingTimer}
            handleTimer={handleTimer}
            timerRunningTask={timerRunningTask?.id}
          />
          <UserProfile
            show={showUserProfileArea}
            handleClose={() => setShowUserProfileArea(false)}
            id={userProfile}
          />
          <TaskInfo
            taskInfoId={taskInfoData}
            showTaskInfo={showTaskInfo}
            handleCloseTaskInfo={handleTaskInfoClose}
            getData={getData}
            afterAssigneeChange={afterAssigneeChange}
            afterStatusChange={afterStatusChange}
            updateTimerStatus={updateTimerStatus}
          />
          <AddAssigneeModal
            show={assigneeUpdateObj.status}
            assignee_name={assigneeUpdateObj.assigneeName}
            assignee_id={assigneeUpdateObj.assigneeId}
            getData={getData}
            taskId={assigneeUpdateObj.task_id}
            handleClose={handleCloseAssigneeUpdate}
            title="UPDATE ASSIGNEE"
            bulkUpdate={assigneeUpdateObj.bulkUpdate}
            selectedTasksId={selectedTasksId}
            afterAssigneeChange={afterAssigneeChange}
          />
          <BulkUpdateModal
            show={showBulkUpdateModal}
            getData={getData}
            handleClose={handleCloseBulkModal}
            selectedTasksId={selectedTasksId}
            permission={permission}
            canUpdateAllStatuses={canUpdateAllStatuses}
          />
          <Card.Header className="d-flex flex-row justify-content-between align-items-center flex-wrap gap-2">
            <div className="d-flex align-items-center">
              <BiTask
                size={isNotFromTask ? 18 : 20}
                className="me-1 text-dark"
              />
              <TableHeader title={Translate("Task Management")} table />
            </div>
            <div className="d-flex flex-wrap gap-1">
              {selectedTasksId.length > 0 &&
                (permission?.updateTaskAssignee || canUpdateAllStatuses) && (
                  <Button
                    size="sm"
                    style={{ fontSize: "12px", padding: "0px 8px" }}
                    onClick={() => setShowBulkUpdateModal(true)}
                    variant="primary"
                  >
                    <TbEditCircle size={16} className="me-1" />
                    {Translate("Bulk Update")}
                  </Button>
                )}
              <CustomizeColumnButton
                columns={columns}
                fromModule={MODULES.TASKS}
              />
              <RefreshButton
                style={{ fontSize: "11px" }}
                loading={isLoading}
                className={"d-flex align-items-center justify-content-center"}
                variant={"info"}
                onClick={() => getData(pageNumber)}
              />
              {loggedAsCustomer || isFromEmployee ? null : (
                <>
                  {permission?.export && (
                    <ExportButton
                      url="crm/task-export"
                      initialMessage={"Task export started"}
                      successMessage={"Task export completed"}
                      file_type={"pdf"}
                      variant={"danger"}
                      filters={filterFields}
                    />
                  )}
                </>
              )}
              {permission?.store && (
                <AddButton
                  route={
                    isFromSprint
                      ? `/sprints/info/${itemInfoId}/task/add?${commonSearchParams}${
                          isFromSprint ? `&project=${renderFromId}` : ""
                        }`
                      : isFromProject
                      ? `/project/info/${itemInfoId}/task/add?${commonSearchParams}`
                      : isFromAccount
                      ? `/account/overview/${client_id}/task/add?${commonSearchParams}`
                      : isFromEmployee
                      ? `/employee/overview/${empId}/task/add?${commonSearchParams}`
                      : `add?${commonSearchParams}`
                  }
                  variant="success"
                  title="Add new task"
                />
              )}
              <FilterCommonButton
                filterVisibility={mainFilterVisibility}
                setFilterVisibility={setMainFilterVisibility}
              />
            </div>
          </Card.Header>
          {mainFilterVisibility && (
            <CommonFilter
              filterKeys={commonTaskFilterFormKeys}
              module="tasks"
              additionalResetParams={{
                unassigned: "",
                tab: isNotFromTask ? "tasklist" : "",
              }}
              buttonColumnWidth={1}
            />
          )}
          {isLoading ? (
            <LoadingScreenCover
              style={{ height: isNotFromTask ? "70vh" : "" }}
              className={`${
                isFromSprint || isFromEmployee || isFromAccount
                  ? "shadow-none"
                  : ""
              } ${!mainFilterVisibility ? "border-top" : ""}`}
            >
              <LoadingCommon loadingText="Looking for Tasks" />
            </LoadingScreenCover>
          ) : tasks?.data ? (
            <>
              {showRenderedContent && (
                <>
                  <SimpleBar
                    className={
                      !mainFilterVisibility
                        ? renderFrom === "project"
                          ? "simplebar-style-with-pagination-for-task-in-project-tab simplebar-custom"
                          : isFromSprint
                          ? "simplebar-style-with-pagination-for-task-in-sprint-tab simplebar-custom"
                          : isFromEmployee
                          ? "simplebar-style-with-pagination-for-task-in-employee-tab simplebar-custom"
                          : isFromAccount
                          ? "simplebar-style-with-pagination-for-task-in-account-tab simplebar-custom"
                          : "simplebar-style-with-pagination simplebar-custom"
                        : renderFrom === "project"
                        ? "simplebar-style-with-pagination-for-task-in-project-tab-filter-on simplebar-custom"
                        : isFromSprint
                        ? "simplebar-style-with-pagination-for-task-in-sprint-tab-filter-on simplebar-custom"
                        : isFromEmployee
                        ? "simplebar-style-with-pagination-for-task-in-employee-tab-filter-on simplebar-custom"
                        : isFromAccount
                        ? "simplebar-style-with-pagination-for-task-in-account-tab-filter-on simplebar-custom"
                        : "simplebar-style-with-pagination-and-filter-on-with-three-row simplebar-custom"
                    }
                  >
                    <Card.Body className="p-0">
                      <AdvanceTableWrapper
                        columns={customColumns}
                        data={tasks?.data}
                        sortable
                        pagination
                        perPage={tasks?.meta?.per_page}
                      >
                        <AdvanceTable
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle position-sticky top-0 z-1"
                          rowClassName="align-middle white-space-nowrap custom-table-row"
                          tablebodyClassName="custom-table-body position-sticky"
                          tableprops={{
                            size: "sm",
                            striped: true,
                            className: "fs--1 mb-0 overflow-hidden",
                          }}
                        />
                      </AdvanceTableWrapper>
                    </Card.Body>
                  </SimpleBar>
                  <Card.Footer className="border-top p-2 footer">
                    {tasks?.links && (
                      <AppPagination
                        data={tasks}
                        url={activeRoute}
                        search={allQueryParams}
                      />
                    )}
                  </Card.Footer>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </Card>
      )}
    </>
  );
};

export default TaskTable;
