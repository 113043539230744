import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { leadAddMeetingFormKeys } from "helpers/formKeys";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import setNewFormData from "helpers/setNewFormData";
import removeRefData from "helpers/removeRefData";
import { apiCall } from "helpers/apiCalls";
import paramsArrayBuilder from "helpers/paramsArrayBuilder";
import AddMeeting from "./AddMeeting";
import ListLoading from "module/common/ListLoading";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { AuthWizardContext } from "context/Context";
import useAxisproTranslate from "hooks/useAxisproTranslate";
const MeetingsForm = ({ getData, type, id }) => {
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const { user } = useContext(AuthWizardContext);
  const [formData, setFormData] = useState({
    ...leadAddMeetingFormKeys,
    host_user_id: user?.id,
    host_user_id_ref: {
      label: user?.name,
      value: user?.id,
    },
  });
  const [formError, setFormError] = useState({});
  const [queryParams] = useSearchParams();
  const show = queryParams.get("add_meeting") ? true : false;
  const edit_meeting_id = queryParams.get("edit_meeting_id");
  const [onSave, setOnSave] = useState(false);
  let { itemId, overviewId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  overviewId = overviewId ? overviewId : id ? id : "";
  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSave) return;

    setFormError({});
    setOnSave(true);
    formData.related_to_id = overviewId ? overviewId : itemId;
    formData.related_to_type =
      type === "LEAD"
        ? "LEAD"
        : type === "CUSTOMER"
        ? "CUSTOMER"
        : "OPPORTUNITY";
    const removeRefDataResults = removeRefData(formData);
    const newFormData = setNewFormData(removeRefDataResults);
    const paramsArray = paramsArrayBuilder(removeRefDataResults);
    const queryParams = new URLSearchParams(paramsArray).toString();
    axios({
      method: edit_meeting_id ? "put" : "post",
      url: `crm/meetings${edit_meeting_id ? "/" + edit_meeting_id : ""}?${
        edit_meeting_id && queryParams
      }`,
      data: edit_meeting_id ? "" : newFormData,
      ...(!edit_meeting_id && {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          setFormData(leadAddMeetingFormKeys);
          navigate(-1);
          getData();
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        setOnSave(false);
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      });
  };

  const fetchData = async (edit_meeting_id) => {
    setIsLoading(true);
    const data = await apiCall({
      url: `crm/meetings/${edit_meeting_id}`,
    });

    const participantsArray = data.participants.map((item) => ({
      label: item.full_name,
      value: item.id,
    }));
    setFormData((prev) => ({
      ...prev,
      title: data.title,
      start_time: data.start_time,
      end_time: data.end_time,
      host_user_id: data.host_user_id,
      host_user_id_ref: {
        label: data.host.first_name + " " + data.host.last_name,
        value: data.host_user_id,
      },
      participants: data.participants.map((items) => items.id),
      location: data.location,
      participants_ref: participantsArray,
      description: data.description,
      status: data?.status?.toUpperCase(),
      status_ref: {
        label: data?.status?.toUpperCase(),
        value: data?.status?.toUpperCase(),
      },
    }));
    setIsLoading(false);
  };
  useEffect(() => {
    if (edit_meeting_id && show) {
      fetchData(edit_meeting_id);
    }
    setFormData({
      ...leadAddMeetingFormKeys,
      host_user_id: user?.id,
      host_user_id_ref: {
        label: user?.name,
        value: user?.id,
      },
    });
    return () => {
      setFormData(leadAddMeetingFormKeys);
      setOnSave(false);
      setFormError({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <Modal show={show} size="lg" className="no-border-radius-modal">
      <Modal.Header className="border-bottom">
        <Modal.Title className=" w-100 d-flex  align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FaUserPlus size={20} className="me-2 text-dark" />
            <div style={{ fontSize: "14px" }}>
              {Translate(edit_meeting_id ? "EDIT MEETING INFO" : "ADD MEETING INFO")}
            </div>
          </div>
          <CustomBackButton
            variant="falcon-default"
            title="Close"
            onClick={() => navigate(-1)}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {isLoading ? (
          <ListLoading style={{ height: "37vh", overflow: "hidden" }} />
        ) : (
          <AddMeeting
            handleFieldChange={handleFieldChange}
            formData={formData}
            formError={formError}
            onSave={onSave}
            handleSubmit={handleSubmit}
            itemId={overviewId ? overviewId : itemId}
            type={type}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
MeetingsForm.prototype = {
  show: PropTypes.bool,
};
export default MeetingsForm;
